export function bootstrapDraggable() {

    document.addEventListener('DOMContentLoaded', () => {

        init();

        function init() {

            let section_elems = document.querySelectorAll('.section__content');

            if(section_elems) {

                Array.prototype.forEach.call(section_elems, section => {
                   
                    let mods = section.querySelectorAll('.mod')

                    Array.prototype.forEach.call(mods, mod => {
                        
                        let move_btn = mod.querySelector('.action--move')

                        move_btn.addEventListener('dragstart', (e) => onDraggingStart(e,move_btn.href, mod.id) )
                        move_btn.addEventListener('dragend',  (e) =>  onDraggingEnd(e, move_btn.href, mod.id) )

                    })

                    section.addEventListener('dragover', e => {

                        const draggable = document.querySelector('.dragging')
                        const afterElement = getDragAfterElement(section, e.clientY)

                        if(afterElement == null) {
                            section.insertBefore(draggable, section.getElementsByClassName('addmodule')[0])
                        } else {
                            section.insertBefore(draggable, afterElement)
                        }

                    })

                })

            }
            
        }
                
        function onDraggingStart(e, actionURL, draggableID) {
            let draggable = e.target.closest(".mod")
            draggable.classList.add('dragging')
        }

        async function onDraggingEnd(e, actionURL, draggableID) {
            
            let draggable = e.target.closest(".mod")
            
            let newsectionElem = draggable.closest('.section')
            let newsectionID = newsectionElem.id.match(/\d+/)[0]  

            draggable.classList.remove('dragging')
            
            // let's get all of element ids in the current order (safter moving around by user)
            let sequence = [];
            newsectionElem.querySelectorAll(".mod").forEach(
                node => { 
                    sequence.push( { 'id': node.id.match(/\d+/)[0], 'type': node.dataset.type } ) 
                }
            )

            // update the field "sequence" in DB
            let options = {
                method: "POST", 
                body: JSON.stringify({
                    'mod':        draggableID.match(/\d+/)[0],
                    'newsection': newsectionID,
                    "sequence":   sequence
                })
            }

            const res = await fetch(actionURL, options).
            then(response => response.json() ).
            then(
                (data) => {
                    if(!data.error)
                    {   
                        let actionElems = draggable.getElementsByClassName('action')
                        Array.prototype.forEach.call(actionElems, elem => {
                            elem.href = updateActionURLs(elem.href, 'section', newsectionID);
                        })

                    }
                }
            )

        }

        function getDragAfterElement(section, y) {

            let draggableElems = [...section.querySelectorAll('.mod:not(.dragging)')]

            return draggableElems.reduce(
                (closest, child) => {
                    
                    const box = child.getBoundingClientRect()
                    const offset = y - box.top - box.height / 2
                    
                    if(offset < 0 && offset > closest.offset) {
                        return { offset: offset, element: child}
                    } else {
                        return closest
                    }

                },
                { offset: Number.NEGATIVE_INFINITY }
            ).element

        }

        function updateActionURLs(url, urlParam, paramVal) {
            
            var url = new URL(url);
            var search_params = url.searchParams;

            // new value of "id" is set to "newsectionID"
            if(search_params.get(urlParam)) {
                search_params.set(urlParam, paramVal);
            }

            // change the search property of the main url
            url.search = search_params.toString();

            // the new url string
            var new_url = url.toString();

            return new_url     
        }

    })

}